"use client";
import Hero from "@/components/about/hero";
import ProductSection from "@/components/about/product-section";
import ContactForm from "@/components/contact-form";
import LaunchBuildInspire from "@/components/about/launch-build-inspire";
import MenuButton from "@/components/nav/menu-button";
import MobileButton from "@/components/nav/mobile-button";

export default function Page() {
  return (
    <>
      <div className="h-dvh w-full flex flex-col justify-center justify-items-center items-center">
        <h1 className="text-9xl text-mvnx-primary font-normal">404</h1>
        <h1 className="mt-2 text-xl text-gray-600 font-normal">
          This page does not exist.
        </h1>
        <div className="mt-5">
          <MobileButton
            title="Return Home"
            href="/"
            style="button"
            onNav={() => {}}
          />
        </div>
      </div>
    </>
  );
}
